import { cn } from "~/common/shadcn-utils"
import { typographyVariants } from "~/ui/Typography"
import { usePageTitle } from "~/common/usePageTitle"
import { IssuesProvider, IssuesTable } from "~/components/admin/IssuesTable"
import { Button } from "~/shadcn/ui/button"
import { PlusIcon } from "lucide-react"
import { IssueDialog } from "~/components/IssueDialog"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
  AdminIssuesQueryVariables,
  Issue_AdminFragment,
} from "~/__generated__/graphql"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"

const PAGE_SIZE = 20

export const AdminIssuesScreen = () => {
  usePageTitle("Issues")

  const [issues, setIssues] = useState<Issue_AdminFragment[]>([])
  const [isCreateIssueDialogOpen, setIsCreateIssueDialogOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const [after, setAfter] = useState<string | undefined>(
    searchParams.get("after") || undefined
  )
  const [before, setBefore] = useState<string | undefined>(
    searchParams.get("before") || undefined
  )
  const first = useMemo(() => {
    if (!before) return PAGE_SIZE
    return undefined
  }, [before])
  const last = useMemo(() => {
    if (before) return PAGE_SIZE
    return undefined
  }, [before])

  const queryOptions = useMemo(() => {
    const options: AdminIssuesQueryVariables = {}
    if (first) options.first = first
    if (last) options.last = last
    if (before) options.before = before
    if (after) options.after = after
    if (Object.keys(options).length === 0) return {}
    return { variables: options }
  }, [first, last, before, after])

  const { data, loading, error } = useQuery(ISSUES_QUERY_DOCUMENT, queryOptions)

  const memoizedSearchParams = useMemo(() => {
    const params = new URLSearchParams(searchParams.toString())
    if (after) {
      params.set("after", after)
    } else {
      params.delete("after")
    }
    if (before) {
      params.set("before", before)
    } else {
      params.delete("before")
    }

    return params
  }, [after, before, searchParams])

  useEffect(() => {
    setSearchParams(memoizedSearchParams)
  }, [memoizedSearchParams, setSearchParams])

  useEffect(() => {
    if (data?.issues.edges) {
      setIssues(
        data.issues.edges.map((edge) => edge.node) as Issue_AdminFragment[]
      )
    }
  }, [data])

  const hasNextPage = !!data?.issues.pageInfo.hasNextPage
  const hasPreviousPage = !!data?.issues.pageInfo.hasPreviousPage
  const goToNextPage = () => {
    if (!data?.issues.pageInfo.endCursor) return

    setBefore(undefined)
    setAfter(data?.issues.pageInfo.endCursor)
  }

  const goToPreviousPage = () => {
    if (!data?.issues.pageInfo.startCursor) return

    setAfter(undefined)
    setBefore(data?.issues.pageInfo.startCursor)
  }

  const onSaved = useCallback((issue: Issue_AdminFragment) => {
    setIssues((prev) => {
      const index = prev.findIndex((i) => i.id === issue.id)
      if (index === -1) {
        return [issue, ...prev]
      }

      return [...prev.slice(0, index), issue, ...prev.slice(index + 1)]
    })
  }, [])

  return (
    <IssuesProvider
      issues={issues}
      setIssues={setIssues}
      loading={loading}
      error={error}
      hasNextPage={hasNextPage}
      hasPreviousPage={hasPreviousPage}
      goToNextPage={goToNextPage}
      goToPreviousPage={goToPreviousPage}
    >
      <div className="container mx-auto max-w-7xl flex flex-col items-center gap-8">
        <h2
          className={cn(
            typographyVariants({ variant: "h2" }),
            "mt-16 text-center"
          )}
        >
          Issues
        </h2>

        <div className="flex justify-end w-full">
          <IssueDialog
            open={isCreateIssueDialogOpen}
            setOpen={setIsCreateIssueDialogOpen}
            onSaved={onSaved}
          />
          <Button onClick={() => setIsCreateIssueDialogOpen(true)}>
            <PlusIcon className="w-4 h-4 mr-1" /> Add Issue
          </Button>
        </div>

        <IssuesTable
          loading={loading}
          error={error}
          issues={issues}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          goToNextPage={goToNextPage}
          goToPreviousPage={goToPreviousPage}
        />
      </div>
    </IssuesProvider>
  )
}

export const ISSUES_QUERY_DOCUMENT = gql(`
  query AdminIssues(
    $first: Int,
    $last: Int,
    $before: String,
    $after: String,
  ) {
    issues(
      first: $first,
      last: $last,
      before: $before,
      after: $after,
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...Issue_Admin
        }
      }
    }
  }
`)
