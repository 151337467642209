import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "~/common/shadcn-utils"
import { Link, LinkProps } from "react-router-dom"

const buttonVariants = cva(
  [
    "inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
    "w-min whitespace-nowrap no-underline rounded font-semibold",
  ].join(" "),
  {
    variants: {
      variant: {
        default: "bg-primary text-white hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-gray-333 hover:bg-accent hover:text-accent-foreground font-normal",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "underline-offset-4 hover:underline text-primary border-none font-normal",
        input:
          "flex rounded-none !h-11 w-full border border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      },
      size: {
        default: "h-10 py-2 px-4",
        sm: "h-9 px-3",
        lg: "h-14 px-8",
        full: "h-10 w-full",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

type SharedProps = {
  isLoading?: boolean
}

export interface ButtonProps
  extends SharedProps,
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      isLoading,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {isLoading ? "Saving..." : children}
      </Comp>
    )
  }
)
Button.displayName = "Button"

export interface LinkButtonProps
  extends SharedProps,
    LinkProps,
    VariantProps<typeof buttonVariants> {}

const LinkButton = ({
  className,
  variant,
  size,
  isLoading,
  children,
  ...props
}: LinkButtonProps) => {
  return (
    <Link
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
    >
      {isLoading ? "Saving..." : children}
    </Link>
  )
}
LinkButton.displayName = "LinkButton"

export interface FakeButtonProps
  extends SharedProps,
    React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof buttonVariants> {}

const FakeButton = ({
  className,
  variant,
  size,
  isLoading,
  children,
  ...props
}: FakeButtonProps) => {
  return (
    <div className={cn(buttonVariants({ variant, size, className }))}>
      {isLoading ? "Saving..." : children}
    </div>
  )
}
FakeButton.displayName = "FakeButton"

export { Button, buttonVariants, LinkButton, FakeButton }
