// use static-path to generate type-safe paths
// https://github.com/garybernhardt/static-path

import { path } from "static-path"

export const subscribePath = path("/")
export const accountPath = path("/account")
export const digitalArchivePath = path("/digital-archive")
export const signInPath = path("/sign-in")
export const signInTokenPath = path("/users/sign_in/:sessionId")
export const adminUsersPath = path("/admin/users")
export const adminIssuesPath = path("/admin/issues")

// server routes
export const tokenConfirmationPath = path("/users/sign_in/:id")
export const signOutPath = path("/users/sign_out")
export const goodJobPath = path("/good_job")
