import { zodResolver } from "@hookform/resolvers/zod"
import { format, parseISO } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { z } from "zod"
import { gql } from "~/__generated__"
import { Issue_AdminFragment } from "~/__generated__/graphql"
import { cn } from "~/common/shadcn-utils"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/shadcn/ui/button"
import { Calendar } from "~/shadcn/ui/calendar"
import { Card, CardContent } from "~/shadcn/ui/card"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Input } from "~/shadcn/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "~/shadcn/ui/popover"
import { Separator } from "~/shadcn/ui/separator"
import { Switch } from "~/shadcn/ui/switch"
import { Textarea } from "~/shadcn/ui/textarea"

const issueFormSchema = z.object({
  title: z.string().min(1),
  number: z.string().min(1),
  fulfillmentStart: z.date(),
  availableInLibrary: z.boolean(),
  embedCode: z.string(),
})

type IssueFormValues = z.infer<typeof issueFormSchema>

interface IssueFormProps {
  issue?: Issue_AdminFragment
  onSaved: (issue: Issue_AdminFragment) => void
  onCancel: () => void
}

export const IssueForm = ({ issue, onSaved, onCancel }: IssueFormProps) => {
  const [upsertIssue, { loading }] = useSafeMutation(ISSUE_UPSERT_MUTATION)

  const form = useForm<IssueFormValues>({
    resolver: zodResolver(issueFormSchema),
    defaultValues: {
      title: issue?.title ?? "",
      number: issue?.number ?? "",
      fulfillmentStart: issue?.fulfillmentStart
        ? parseISO(issue.fulfillmentStart)
        : new Date(),
      availableInLibrary: issue?.availableInLibrary ?? false,
      embedCode: issue?.embedCode ?? "",
    },
  })

  useEffect(() => {
    form.reset({
      title: issue?.title ?? "",
      number: issue?.number ?? "",
      fulfillmentStart: issue?.fulfillmentStart
        ? parseISO(issue.fulfillmentStart)
        : new Date(),
      availableInLibrary: issue?.availableInLibrary ?? false,
      embedCode: issue?.embedCode ?? "",
    })
  }, [issue, form])

  const onSubmit = async (values: IssueFormValues) => {
    const { data } = await upsertIssue({
      variables: {
        input: {
          issueId: issue?.id,
          ...values,
        },
      },
    })

    if (data?.issueUpsert?.issue) {
      onSaved(data.issueUpsert.issue as Issue_AdminFragment)
    } else {
      toast.error("Error saving issue")
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder="Winter 2025" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Number</FormLabel>
              <FormControl>
                <Input placeholder="25" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="fulfillmentStart"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Fulfillment Start</FormLabel>
              <div>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-[240px] pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) => date < new Date("2020-01-01")}
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="embedCode"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Embed Code</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Paste your embed code from FlippingBook"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="availableInLibrary"
          render={({ field }) => (
            <FormItem>
              <Card heft="light">
                <CardContent className="pt-4 flex flex-row gap-4 items-center justify-between">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">
                      Available in Archives
                    </FormLabel>
                    <FormDescription>
                      If checked, this issue will be available in the archives
                      for subscribers.
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </CardContent>
              </Card>
              <FormMessage />
            </FormItem>
          )}
        />

        <Separator />

        <div className="flex justify-between gap-4">
          <Button variant="outline" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>

          <Button type="submit" disabled={loading}>
            Save
          </Button>
        </div>
      </form>
    </Form>
  )
}

const ISSUE_UPSERT_MUTATION = gql(`
  mutation IssueUpsert($input: IssueUpsertInput!) {
    issueUpsert(input: $input) {
      issue {
        ...Issue_Admin
      }
    }
  }
`)
