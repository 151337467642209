import { ErrorResponse } from "react-router-dom"

abstract class ApplicationError
  extends Response
  implements Pick<ErrorResponse, "status" | "statusText" | "data">
{
  status: number = 500
  statusText: string = "Internal Server Error"
  data: any = null
}

export class NotFoundError extends ApplicationError {
  constructor() {
    super("Not Found", { status: 404 })
    this.status = 404
    this.statusText = "Not Found"
  }
}

export function isNotFoundError(value: unknown): value is NotFoundError {
  return value instanceof NotFoundError
}
