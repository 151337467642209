import { useEffect } from "react"
import toast from "react-hot-toast"
import { Outlet, useNavigate } from "react-router-dom"
import { signInPath } from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"

export const RequireAdmin = () => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUserMaybe()

  useEffect(() => {
    if (currentUser?.admin === false) {
      toast.error("Please sign in to continue.")
      navigate(signInPath.pattern)
    }
  }, [currentUser, navigate])

  if (currentUser?.admin === false) {
    return null
  }

  return <Outlet />
}
