import { Outlet } from "react-router-dom"
import { Navigation } from "./elements/Navigation"
import { Footer } from "./elements/Footer"

export const AuthenticatedLayout = () => {
  return (
    <>
      <Navigation variant="followerScrolled" />
      <div className="min-h-screen flex flex-col justify-between">
        <div>
          <div className="h-16" />
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  )
}
