/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query CurrentUserProvider {\n    currentUser {\n      id\n      admin\n      email\n      phone\n      firstName\n      lastName\n      shippingAddress {\n        id\n        line1\n        line2\n        city\n        state\n        postalCode\n        country\n        name\n        phone\n      }\n      stripeSubscription {\n        currentPeriodEnd\n\n        subscriptionPlan {\n          id\n          name\n          unitAmountFormatted\n          interval\n          intervalCount\n          marketingFeatures\n        }\n      }\n      __typename\n    }\n  }\n": types.CurrentUserProviderDocument,
    "\n  mutation IssueUpsert($input: IssueUpsertInput!) {\n    issueUpsert(input: $input) {\n      issue {\n        ...Issue_Admin\n      }\n    }\n  }\n": types.IssueUpsertDocument,
    "\n  fragment Issue_Admin on Issue {\n    id\n    title\n    number\n    fulfillmentStart\n    availableInLibrary\n    embedCode\n    createdAt\n    updatedAt\n  }\n": types.Issue_AdminFragmentDoc,
    "\n  fragment User_Admin on User {\n    id\n    admin\n    firstName\n    lastName\n    email\n    phone\n    createdAt\n    updatedAt\n    stripeCustomerId\n\n    firstIssue {\n      id\n      title\n      number\n      fulfillmentStart\n    }\n\n    shippingAddress {\n      id\n      line1\n      line2\n      city\n      state\n      postalCode\n      country\n    }\n\n    stripeSubscription {\n      id\n      stripeSubscriptionId\n      status\n      currentPeriodEnd\n      currentPeriodStart\n      cancelAtPeriodEnd\n      canceledAt\n      endedAt\n      startDate\n      createdAt\n      updatedAt\n\n      subscriptionPlan {\n        id\n        name\n        unitAmountFormatted\n        interval\n        intervalCount\n      }\n    }\n  }\n": types.User_AdminFragmentDoc,
    "\n  query AdminUsers(\n    $first: Int,\n    $last: Int,\n    $before: String,\n    $after: String,\n    $sort: UserSortEnum,\n    $query: String,\n    $subscriptionStatus: StripeSubscriptionStatusEnum,\n  ) {\n    users(\n      first: $first,\n      last: $last,\n      before: $before,\n      after: $after,\n      sort: $sort,\n      query: $query,\n      subscriptionStatus: $subscriptionStatus,\n    ) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      nodes {\n        ...User_Admin\n      }\n    }\n  }\n": types.AdminUsersDocument,
    "\n  query AdminIssues(\n    $first: Int,\n    $last: Int,\n    $before: String,\n    $after: String,\n  ) {\n    issues(\n      first: $first,\n      last: $last,\n      before: $before,\n      after: $after,\n    ) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        node {\n          ...Issue_Admin\n        }\n      }\n    }\n  }\n": types.AdminIssuesDocument,
    "\n  fragment Issue_Public on Issue {\n    id\n    title\n    number\n    embedCode\n    availableInLibrary\n    canRead { value }\n  }\n": types.Issue_PublicFragmentDoc,
    "\n  query Issues($first: Int, $after: String, $before: String, $last: Int, $readableOnly: Boolean, $libraryOnly: Boolean) {\n    issues(first: $first, after: $after, before: $before, last: $last, readableOnly: $readableOnly, libraryOnly: $libraryOnly) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        node {\n          ...Issue_Public\n        }\n      }\n    }\n  }\n": types.IssuesDocument,
    "\n  mutation SendMagicLink($input: SendMagicLinkInput!) {\n    sendMagicLink(input: $input) {\n      sessionId\n    }\n  }\n": types.SendMagicLinkDocument,
    "\n  query SubscriptionPlans {\n    latestIssue {\n      id\n      title\n      number\n    }\n\n    subscriptionPlans {\n      edges {\n        node {\n          id\n          name\n          description\n          marketingFeatures\n          unitAmountFormatted\n          interval\n          intervalCount\n        }\n      }\n    }\n  }\n": types.SubscriptionPlansDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CurrentUserProvider {\n    currentUser {\n      id\n      admin\n      email\n      phone\n      firstName\n      lastName\n      shippingAddress {\n        id\n        line1\n        line2\n        city\n        state\n        postalCode\n        country\n        name\n        phone\n      }\n      stripeSubscription {\n        currentPeriodEnd\n\n        subscriptionPlan {\n          id\n          name\n          unitAmountFormatted\n          interval\n          intervalCount\n          marketingFeatures\n        }\n      }\n      __typename\n    }\n  }\n"): (typeof documents)["\n  query CurrentUserProvider {\n    currentUser {\n      id\n      admin\n      email\n      phone\n      firstName\n      lastName\n      shippingAddress {\n        id\n        line1\n        line2\n        city\n        state\n        postalCode\n        country\n        name\n        phone\n      }\n      stripeSubscription {\n        currentPeriodEnd\n\n        subscriptionPlan {\n          id\n          name\n          unitAmountFormatted\n          interval\n          intervalCount\n          marketingFeatures\n        }\n      }\n      __typename\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation IssueUpsert($input: IssueUpsertInput!) {\n    issueUpsert(input: $input) {\n      issue {\n        ...Issue_Admin\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation IssueUpsert($input: IssueUpsertInput!) {\n    issueUpsert(input: $input) {\n      issue {\n        ...Issue_Admin\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Issue_Admin on Issue {\n    id\n    title\n    number\n    fulfillmentStart\n    availableInLibrary\n    embedCode\n    createdAt\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment Issue_Admin on Issue {\n    id\n    title\n    number\n    fulfillmentStart\n    availableInLibrary\n    embedCode\n    createdAt\n    updatedAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment User_Admin on User {\n    id\n    admin\n    firstName\n    lastName\n    email\n    phone\n    createdAt\n    updatedAt\n    stripeCustomerId\n\n    firstIssue {\n      id\n      title\n      number\n      fulfillmentStart\n    }\n\n    shippingAddress {\n      id\n      line1\n      line2\n      city\n      state\n      postalCode\n      country\n    }\n\n    stripeSubscription {\n      id\n      stripeSubscriptionId\n      status\n      currentPeriodEnd\n      currentPeriodStart\n      cancelAtPeriodEnd\n      canceledAt\n      endedAt\n      startDate\n      createdAt\n      updatedAt\n\n      subscriptionPlan {\n        id\n        name\n        unitAmountFormatted\n        interval\n        intervalCount\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment User_Admin on User {\n    id\n    admin\n    firstName\n    lastName\n    email\n    phone\n    createdAt\n    updatedAt\n    stripeCustomerId\n\n    firstIssue {\n      id\n      title\n      number\n      fulfillmentStart\n    }\n\n    shippingAddress {\n      id\n      line1\n      line2\n      city\n      state\n      postalCode\n      country\n    }\n\n    stripeSubscription {\n      id\n      stripeSubscriptionId\n      status\n      currentPeriodEnd\n      currentPeriodStart\n      cancelAtPeriodEnd\n      canceledAt\n      endedAt\n      startDate\n      createdAt\n      updatedAt\n\n      subscriptionPlan {\n        id\n        name\n        unitAmountFormatted\n        interval\n        intervalCount\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUsers(\n    $first: Int,\n    $last: Int,\n    $before: String,\n    $after: String,\n    $sort: UserSortEnum,\n    $query: String,\n    $subscriptionStatus: StripeSubscriptionStatusEnum,\n  ) {\n    users(\n      first: $first,\n      last: $last,\n      before: $before,\n      after: $after,\n      sort: $sort,\n      query: $query,\n      subscriptionStatus: $subscriptionStatus,\n    ) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      nodes {\n        ...User_Admin\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminUsers(\n    $first: Int,\n    $last: Int,\n    $before: String,\n    $after: String,\n    $sort: UserSortEnum,\n    $query: String,\n    $subscriptionStatus: StripeSubscriptionStatusEnum,\n  ) {\n    users(\n      first: $first,\n      last: $last,\n      before: $before,\n      after: $after,\n      sort: $sort,\n      query: $query,\n      subscriptionStatus: $subscriptionStatus,\n    ) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      nodes {\n        ...User_Admin\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminIssues(\n    $first: Int,\n    $last: Int,\n    $before: String,\n    $after: String,\n  ) {\n    issues(\n      first: $first,\n      last: $last,\n      before: $before,\n      after: $after,\n    ) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        node {\n          ...Issue_Admin\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminIssues(\n    $first: Int,\n    $last: Int,\n    $before: String,\n    $after: String,\n  ) {\n    issues(\n      first: $first,\n      last: $last,\n      before: $before,\n      after: $after,\n    ) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        node {\n          ...Issue_Admin\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Issue_Public on Issue {\n    id\n    title\n    number\n    embedCode\n    availableInLibrary\n    canRead { value }\n  }\n"): (typeof documents)["\n  fragment Issue_Public on Issue {\n    id\n    title\n    number\n    embedCode\n    availableInLibrary\n    canRead { value }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Issues($first: Int, $after: String, $before: String, $last: Int, $readableOnly: Boolean, $libraryOnly: Boolean) {\n    issues(first: $first, after: $after, before: $before, last: $last, readableOnly: $readableOnly, libraryOnly: $libraryOnly) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        node {\n          ...Issue_Public\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Issues($first: Int, $after: String, $before: String, $last: Int, $readableOnly: Boolean, $libraryOnly: Boolean) {\n    issues(first: $first, after: $after, before: $before, last: $last, readableOnly: $readableOnly, libraryOnly: $libraryOnly) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        node {\n          ...Issue_Public\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendMagicLink($input: SendMagicLinkInput!) {\n    sendMagicLink(input: $input) {\n      sessionId\n    }\n  }\n"): (typeof documents)["\n  mutation SendMagicLink($input: SendMagicLinkInput!) {\n    sendMagicLink(input: $input) {\n      sessionId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SubscriptionPlans {\n    latestIssue {\n      id\n      title\n      number\n    }\n\n    subscriptionPlans {\n      edges {\n        node {\n          id\n          name\n          description\n          marketingFeatures\n          unitAmountFormatted\n          interval\n          intervalCount\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SubscriptionPlans {\n    latestIssue {\n      id\n      title\n      number\n    }\n\n    subscriptionPlans {\n      edges {\n        node {\n          id\n          name\n          description\n          marketingFeatures\n          unitAmountFormatted\n          interval\n          intervalCount\n        }\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;