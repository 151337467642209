import * as Images from "~/images"

interface ImageProps extends React.HTMLAttributes<HTMLImageElement> {
  alt: string
  image: Images.Types
}

export const Image = ({ image, alt, ...props }: ImageProps) => {
  // iterate over all exports from Images to find those that start with the image prop
  const matchingImageFormats = Object.entries(Images).filter(([key]) =>
    key.startsWith(image)
  )

  const srcSet = matchingImageFormats
    .map(([key, value]) => {
      return {
        resolution: parseInt(key.match(/(\d+)x/)?.[1] || "0"),
        type: key.includes("Webp") ? "image/webp" : "image/png",
        url: value,
      }
    })
    .sort((a, b) => (a.resolution || 0) - (b.resolution || 0))
    .sort((a, b) => (a.type === "image/webp" ? -1 : 1))

  return (
    <picture>
      {srcSet.map(({ url, type }) => (
        <source key={url} srcSet={url} type={type} />
      ))}
      <img alt={alt} {...props} src={srcSet[0].url} />
    </picture>
  )
}
