import { useEffect, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getMetaVar } from "~/common/getMetaVar"
import { tokenConfirmationPath } from "~/common/paths"
import { Button } from "~/shadcn/ui/button"
import { OTPInput, SlotProps, REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp"
import { cn } from "~/common/shadcn-utils"
import { Card, CardContent, CardHeader } from "~/shadcn/ui/card"
import Logo from "~/images/logo.svg?react"
import { Link } from "~/ui/Link"

const Slot = (props: SlotProps) => {
  return (
    <div
      className={cn(
        "relative w-[50px] h-[50px] text-xl",
        "flex items-center justify-center",
        "border-[1px] border-gray-E3E2E0 rounded-lg",
        { "border-primary": props.isActive }
      )}
    >
      {props.char !== null && <div>{props.char}</div>}
    </div>
  )
}

export const SignInTokenScreen = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(false)
  const locationData: { state?: { email: string } } = useLocation()
  const { sessionId } = useParams()
  invariant(sessionId)

  const csrfToken = getMetaVar("csrf-token")

  useEffect(() => {
    if (token.length === 6) {
      setLoading(true)
      formRef.current?.submit()
    }
  }, [token.length])

  return (
    <Card className="sm:w-[440px] m-auto">
      <CardHeader className="flex justify-center items-center">
        <Logo className="w-[128px] h-[16px] fill-gray-333" />
      </CardHeader>
      <form
        method="POST"
        action={tokenConfirmationPath({ id: sessionId })}
        ref={formRef}
      >
        <CardContent className="flex flex-col justify-center items-center text-center gap-6">
          <div className="text-lg font-medium leading-7">
            Click the link or enter the code sent to your email to login.
            <br />
            <strong>The codes expire after 10 minutes.</strong>
          </div>
          {locationData.state?.email && <div>{locationData.state.email}</div>}

          <input type="hidden" name="_method" value="patch" />
          <input type="hidden" name="authenticity_token" value={csrfToken} />

          <label className="block">
            <OTPInput
              value={token}
              onChange={(newValue: string) => setToken(newValue.toUpperCase())}
              maxLength={6}
              inputMode="text"
              containerClassName="group flex justify-between w-full gap-1"
              pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
              name="passwordless[token]"
              data-1p-ignore="true"
              render={({ slots }) => (
                <>
                  {slots.map((slot, idx) => (
                    <Slot key={idx} {...slot} />
                  ))}
                </>
              )}
            />
          </label>

          <Button
            size="lg"
            disabled={loading || token.length === 0}
            type="submit"
          >
            Confirm Code
          </Button>

          <p className="text-xs text-gray-333">
            By clicking "Confirm Code" you agree <br />
            with our{" "}
            <Link
              className="whitespace-nowrap"
              to="https://racquetmag.com/legal/"
              rel="nofollow"
              target="_blank"
            >
              Terms of Service
            </Link>
            .
          </p>

          <p>
            Didn't receive a code? <Link to="/sign-in">Send Again</Link>
          </p>
        </CardContent>
      </form>
    </Card>
  )
}
