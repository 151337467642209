import { cn } from "~/common/shadcn-utils"
import { typographyVariants } from "~/ui/Typography"
import { usePageTitle } from "~/common/usePageTitle"
import { AllUsersTable } from "~/components/admin/AllUsersTable"

export const AdminUsersScreen = () => {
  usePageTitle("Subscribers")

  return (
    <div className="container mx-auto flex flex-col items-center gap-16">
      <h2
        className={cn(
          typographyVariants({ variant: "h2" }),
          "mt-16 text-center"
        )}
      >
        Subscribers
      </h2>

      <AllUsersTable />
    </div>
  )
}
