import { Check, ChevronsUpDown, X } from "lucide-react"
import { useState } from "react"
import { StripeSubscriptionStatusEnum } from "~/__generated__/graphql"
import { cn } from "~/common/shadcn-utils"
import { Button, buttonVariants } from "~/shadcn/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/shadcn/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "~/shadcn/ui/popover"

type UsersFiltersProps = {
  subscriptionStatusFilter: StripeSubscriptionStatusEnum | undefined
  setSubscriptionStatusFilter: (
    subscriptionStatusFilter: StripeSubscriptionStatusEnum | undefined
  ) => void
}

const SUBSCRIPTION_STATUSES: StripeSubscriptionStatusEnum[] = [
  StripeSubscriptionStatusEnum.Active,
  StripeSubscriptionStatusEnum.Canceled,
  StripeSubscriptionStatusEnum.Incomplete,
  StripeSubscriptionStatusEnum.IncompleteExpired,
  StripeSubscriptionStatusEnum.PastDue,
  StripeSubscriptionStatusEnum.Trialing,
  StripeSubscriptionStatusEnum.Unpaid,
]

export const SubscriptionStatusFilter = ({
  subscriptionStatusFilter,
  setSubscriptionStatusFilter,
}: Pick<
  UsersFiltersProps,
  "subscriptionStatusFilter" | "setSubscriptionStatusFilter"
>) => {
  const [open, setOpen] = useState(false)

  const onSelect = (status: StripeSubscriptionStatusEnum) => {
    setSubscriptionStatusFilter(status)
    setOpen(false)
  }

  const clear = (e: Pick<Event, "preventDefault" | "stopPropagation">) => {
    e.preventDefault()
    e.stopPropagation()
    setSubscriptionStatusFilter(undefined)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="input"
          role="combobox"
          aria-expanded={open}
          className="min-w-[224px] justify-start"
        >
          <span className="overflow-hidden text-ellipsis whitespace-nowrap !inline-block text-sm flex-grow text-left">
            {subscriptionStatusFilter || "Subscription Status"}
          </span>
          <div className="flex items-right items-center justify-center">
            <div
              className={cn(
                buttonVariants({ variant: "ghost", size: "icon" }),
                "ml-2 shrink-0",
                subscriptionStatusFilter ? "opacity-100" : "opacity-0"
              )}
              onClick={clear}
            >
              <X className="w-5 h-5" />
            </div>

            <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Command>
          <CommandInput />
          <CommandEmpty>{"No statuses found"}</CommandEmpty>
          <CommandGroup>
            <CommandList>
              {SUBSCRIPTION_STATUSES.map((status) => (
                <CommandItem key={status} onSelect={() => onSelect(status)}>
                  <Check
                    className={cn(
                      "w-5 h-5 mr-2",
                      subscriptionStatusFilter === status
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                  {status}
                </CommandItem>
              ))}
            </CommandList>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export const UsersFilters = ({
  subscriptionStatusFilter,
  setSubscriptionStatusFilter,
}: UsersFiltersProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
      <SubscriptionStatusFilter
        subscriptionStatusFilter={subscriptionStatusFilter}
        setSubscriptionStatusFilter={setSubscriptionStatusFilter}
      />
    </div>
  )
}
