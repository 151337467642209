import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import * as paths from "~/common/paths"
import { ErrorBoundary } from "./components/ErrorBoundary"
import { SignInScreen } from "./screens/SignInScreen"
import { SignInTokenScreen } from "./screens/SignInTokenScreen"
import { RequireUserSignedOut } from "./layouts/RequireUserSignedOut"
import { RequireUserSignedIn } from "./layouts/RequireUserSignedIn"
import { AccountScreen } from "./screens/AccountScreen"
import { HeroLayout } from "./layouts/HeroLayout"
import { LandingLayout } from "./layouts/LandingLayout"
import { SubscribeScreen } from "./screens/SubscribeScreen"
import { AuthenticatedLayout } from "./layouts/AuthenticatedLayout"
import { NotFoundError } from "./errors"
import { RequireAdmin } from "./layouts/RequireAdmin"
import { AdminUsersScreen } from "./screens/AdminUsersScreen"
import { RequireNotAdmin } from "./layouts/RequireNotAdmin"
import { AdminIssuesScreen } from "./screens/AdminIssuesScreen"
import { DigitalArchiveScreen } from "./screens/DigitalArchiveScreen"
import { RequireUserActiveSubscription } from "./layouts/RequireUserActiveSubscription"

const NotFound = () => {
  throw new NotFoundError()
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ErrorBoundary />}>
      <Route element={<RequireUserSignedIn />}>
        <Route element={<AuthenticatedLayout />}>
          <Route element={<RequireAdmin />}>
            <Route
              path={paths.adminUsersPath.pattern}
              element={<AdminUsersScreen />}
            />
            <Route
              path={paths.adminIssuesPath.pattern}
              element={<AdminIssuesScreen />}
            />
          </Route>
          <Route element={<RequireNotAdmin />}>
            <Route
              path={paths.accountPath.pattern}
              element={<AccountScreen />}
            />
          </Route>
          <Route element={<RequireUserActiveSubscription />}>
            <Route
              path={paths.digitalArchivePath.pattern}
              element={<DigitalArchiveScreen />}
            />
          </Route>
        </Route>
      </Route>

      <Route element={<RequireUserSignedOut />}>
        <Route element={<HeroLayout />}>
          <Route path={paths.signInPath.pattern} element={<SignInScreen />} />
          <Route
            path={paths.signInTokenPath.pattern}
            element={<SignInTokenScreen />}
          />
        </Route>
        <Route element={<LandingLayout />}>
          <Route path="/" element={<SubscribeScreen />} />
        </Route>
      </Route>

      <Route path="*" element={<NotFound />} />
    </Route>
  )
)
