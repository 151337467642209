import {
  StripeSubscription,
  SubscriptionPlan,
  User,
  UserAddress,
} from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { signOutPath } from "~/common/paths"
import { cn } from "~/common/shadcn-utils"
import { buttonVariants } from "~/shadcn/ui/button"
import { Link } from "~/ui/Link"
import { typographyVariants } from "~/ui/Typography"
import { format } from "date-fns"
import { usePageTitle } from "~/common/usePageTitle"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { MarketingFeatures } from "~/subscription-plans/MarketingFeatures"
import { Address } from "~/components/Address"
import formatPhoneNumber from "~/common/formatPhoneNumber"

type AccountUser = Pick<
  User,
  "firstName" | "lastName" | "email" | "phone" | "admin"
> & {
  shippingAddress: Pick<
    UserAddress,
    | "name"
    | "line1"
    | "line2"
    | "city"
    | "state"
    | "country"
    | "postalCode"
    | "phone"
  >
} & {
  stripeSubscription: Pick<StripeSubscription, "currentPeriodEnd"> & {
    subscriptionPlan: Pick<
      SubscriptionPlan,
      | "name"
      | "unitAmountFormatted"
      | "interval"
      | "intervalCount"
      | "marketingFeatures"
    >
  }
}

type UserField = {
  label: string
  key?: string
  getValue?: (user: AccountUser) => React.ReactNode
}

const USER_FIELDS: UserField[] = [
  { label: "Name", getValue: (user) => `${user.firstName} ${user.lastName}` },
  { label: "Email", key: "email" },
  {
    label: "Phone",
    getValue: ({ phone }) => (phone ? formatPhoneNumber(phone) : ""),
  },
  {
    label: "Shipping Address",
    getValue: (user) =>
      user.shippingAddress && <Address address={user.shippingAddress} />,
  },
  {
    label: "Tier",
    getValue: (user) => user.stripeSubscription?.subscriptionPlan.name,
  },
  {
    label: "Price",
    getValue: (user) => {
      const plan = user.stripeSubscription?.subscriptionPlan

      if (!plan) {
        return null
      }

      if (plan.intervalCount === 1) {
        if (plan.interval === "year") {
          return `${plan.unitAmountFormatted} Annually`
        } else {
          return `${plan.unitAmountFormatted} Monthly`
        }
      }

      return `${plan.unitAmountFormatted} / ${plan.interval}`
    },
  },
  {
    label: "Upcoming Billing",
    getValue: (user) =>
      user.stripeSubscription?.currentPeriodEnd
        ? format(user.stripeSubscription?.currentPeriodEnd, "MMMM do, y")
        : null,
  },
]

export const AccountScreen = () => {
  usePageTitle("My Account")
  const currentUser = useCurrentUser() as AccountUser

  return (
    <div className="container sm:max-w-[500px] mx-auto flex flex-col items-center gap-16">
      <h2
        className={cn(
          typographyVariants({ variant: "h2" }),
          "mt-16 text-center"
        )}
      >
        My Account
      </h2>
      {currentUser.stripeSubscription && (
        <Card className="w-full" heft="medium">
          <CardHeader>
            <CardTitle variant="h4">Membership</CardTitle>
          </CardHeader>
          <CardContent>
            {currentUser.stripeSubscription.subscriptionPlan
              ?.marketingFeatures && (
              <MarketingFeatures
                features={
                  currentUser.stripeSubscription?.subscriptionPlan
                    .marketingFeatures
                }
              />
            )}
          </CardContent>
        </Card>
      )}
      <div className="grid grid-cols-1 divide-y divide-gray-200 w-full ">
        {USER_FIELDS.map((field) => (
          <div
            key={field.label}
            className="sm:flex sm:gap-4 sm:w-auto w-full py-2 sm:px-0 px-4"
          >
            <label className="text-gray-333 font-bold sm:w-44 w-full">
              {field.label}
            </label>
            <div className="sm:w-[346px] w-full">
              {field.getValue
                ? field.getValue(currentUser)
                : (currentUser as any)[field.key!]}
            </div>
          </div>
        ))}
      </div>
      <Link
        href={currentUser.stripeSubscription ? "/billing" : "/checkout"}
        method={currentUser.stripeSubscription ? "GET" : "POST"}
        className={buttonVariants({ variant: "outline" })}
        target="_blank"
        rel="nofollow"
      >
        Manage Subscription
      </Link>
      <Link
        href={signOutPath.pattern}
        className={buttonVariants({ variant: "ghost" })}
      >
        Sign Out
      </Link>
    </div>
  )
}
