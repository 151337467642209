import { NavBar, NavBarVariants } from "~/ui/NavBar"
import Logo from "~/images/logo.svg?react"
import { Link } from "~/ui/Link"
import { Button } from "~/shadcn/ui/button"
import { VariantProps } from "class-variance-authority"
import { cn } from "~/common/shadcn-utils"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import {
  accountPath,
  adminIssuesPath,
  adminUsersPath,
  digitalArchivePath,
  signInPath,
  signOutPath,
} from "~/common/paths"

type NavigationProps = {
  variant: VariantProps<typeof NavBarVariants>["variant"]
}

export const Navigation = ({ variant }: NavigationProps) => {
  const { currentUser } = useCurrentUserMaybe()

  return (
    <NavBar variant={variant}>
      {(variant) => (
        <>
          <Link href="https://racquetmag.com/">
            <Logo
              className={cn(
                "w-[200px] h-[26px] transition-all fill-current",
                variant === "followerScrolled"
                  ? "text-foreground"
                  : "text-white"
              )}
              aria-label="Racquet Magazine Logo"
            />
          </Link>
          <div className="flex gap-10 items-center uppercase">
            {!currentUser?.admin && (
              <>
                <Link
                  variant="nav"
                  href="https://racquetmag.com/"
                  className="hidden sm:inline"
                >
                  Discover
                </Link>
                <Link
                  variant="nav"
                  href="https://shop.racquetmag.com/"
                  className="hidden sm:inline"
                >
                  Shop
                </Link>
                {currentUser ? (
                  <>
                    <Link to={digitalArchivePath.pattern} variant="nav">
                      Digital Archive
                    </Link>
                    <Link to={accountPath.pattern} variant="nav">
                      Account
                    </Link>
                  </>
                ) : (
                  <Button
                    variant="outline"
                    className="-ml-4 text-md border-white"
                    asChild
                  >
                    <Link to={signInPath.pattern} variant="nav">
                      Sign In
                    </Link>
                  </Button>
                )}
              </>
            )}

            {currentUser?.admin && (
              <>
                <Link to={digitalArchivePath.pattern} variant="nav">
                  Digital Archive
                </Link>
                <Link to={adminIssuesPath.pattern} variant="nav">
                  Issues
                </Link>
                <Link to={adminUsersPath.pattern} variant="nav">
                  Subscribers
                </Link>
                <Link href={signOutPath.pattern} variant="nav">
                  Sign Out
                </Link>
              </>
            )}
          </div>
        </>
      )}
    </NavBar>
  )
}
