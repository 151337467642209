import { useEffect, useMemo, useState } from "react"
import { Button } from "~/shadcn/ui/button"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/ui/table"
import { useQuery } from "@apollo/client"
import { Skeleton } from "~/shadcn/ui/skeleton"
import { useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from "~/shadcn/ui/pagination"
import { UsersSearch } from "./UsersSearch"
import { SubscriptionStatusFilter } from "./UsersFilters"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { cn } from "~/common/shadcn-utils"
import { gql } from "~/__generated__"
import {
  AdminUsersQueryVariables,
  StripeSubscriptionStatusEnum,
  UserSortEnum,
  User_AdminFragment,
} from "~/__generated__/graphql"
import { getMetaVar } from "~/common/getMetaVar"
import { AdminTableHeader } from "."

const pinnedColumnStyles =
  "sticky left-0 bg-gradient-to-r from-white from-80% to-transparent group-hover:from-muted group-hover:from-80% group-hover:to-transparent"

export const TableSkeleton = ({ headers }: { headers: AdminTableHeader[] }) => (
  <Table className="rounded-tl-2xl">
    <TableHeader>
      <TableRow className="group rounded-tl-2xl">
        {headers.map((header) => (
          <TableHead
            key={header.label}
            className={cn(header.isPinned && pinnedColumnStyles)}
          >
            <div className={cn(header.isPinned && "pr-8")}>{header.label}</div>
          </TableHead>
        ))}
      </TableRow>
    </TableHeader>
    <TableBody>
      {Array.from({ length: 20 }).map((_, index) => (
        <TableRow key={index}>
          {headers.map((header) => (
            <TableCell key={[index, header].join("-")}>
              {header.label === "Name" && (
                <div className="flex gap-2">
                  <div>
                    <Skeleton className="rounded-full w-[24px] h-[24px]" />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-2 items-center">
                      <Skeleton className="w-[80px] h-[16px]" />
                    </div>
                    <div className="text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis max-w-[140px] text-2xs">
                      <Skeleton className="w-[100px] h-[16px]" />
                    </div>
                  </div>
                </div>
              )}
              {header.label !== "Name" && (
                <Skeleton className="w-[80px] h-[16px]" />
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
)

const DEFAULT_SORT = UserSortEnum.Name
const PAGE_SIZE = 20

type UsersTableProps = {
  refresh?: number
  withFilters?: boolean
  withSearch?: boolean
  withSelectAll?: boolean
  onSelectAll?(users: User_AdminFragment[]): void
  withExport?: boolean
  withExportLoading?: boolean
  queryOptions?: AdminUsersQueryVariables
  defaultSort?: UserSortEnum
  pageSize?: number
  headers?: AdminTableHeader[]
  skeleton?: React.ReactNode
  variant?: "table" | "card"
  children(user: User_AdminFragment): React.ReactNode
  className?: string
}

export const UsersTable = ({
  children,
  refresh,
  headers,
  skeleton,
  withFilters,
  withSearch,
  withSelectAll,
  withExport,
  onSelectAll,
  queryOptions,
  className = "",
  variant = "table",
  defaultSort = DEFAULT_SORT,
  pageSize = PAGE_SIZE,
}: UsersTableProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [subscriptionStatusFilter, setSubscriptionStatusFilter] = useState<
    StripeSubscriptionStatusEnum | undefined
  >(
    (searchParams.get("subscriptionStatus") as StripeSubscriptionStatusEnum) ||
      undefined
  )

  const [query, setQuery] = useState<string>(searchParams.get("q") || "")
  const [sort, setSort] = useState<UserSortEnum>(
    (searchParams.get("sort") as UserSortEnum) || defaultSort
  )

  const [after, setAfter] = useState<string | undefined>(
    searchParams.get("after") || undefined
  )
  const [before, setBefore] = useState<string | undefined>(
    searchParams.get("before") || undefined
  )

  const [debouncedQuery] = useDebounce(query, 400)

  const first = useMemo(() => {
    if (!before) return pageSize
    return undefined
  }, [before, pageSize])
  const last = useMemo(() => {
    if (before) return pageSize
    return undefined
  }, [before, pageSize])

  const useQueryOptions = useMemo(() => {
    const options: AdminUsersQueryVariables = {}
    if (Object.keys(queryOptions || {}).length > 0) {
      Object.assign(options, queryOptions)
    }
    if (first) options.first = first
    if (last) options.last = last
    if (before) options.before = before
    if (after) options.after = after
    if (sort) options.sort = sort
    if (debouncedQuery) options.query = debouncedQuery
    if (subscriptionStatusFilter)
      options.subscriptionStatus = subscriptionStatusFilter

    if (Object.keys(options).length === 0) return {}
    return { variables: options }
  }, [
    first,
    last,
    before,
    after,
    sort,
    debouncedQuery,
    subscriptionStatusFilter,
    queryOptions,
  ])

  const { data, loading, error, refetch } = useQuery(
    USERS_QUERY_DOCUMENT,
    useQueryOptions
  )

  useEffect(() => {
    if (refresh && refresh > 0) {
      refetch()
    }
  }, [refresh, refetch])

  const users = useMemo(
    () => (data?.users.nodes as User_AdminFragment[]) || [],
    [data]
  )
  const hasNextPage = data?.users.pageInfo.hasNextPage
  const hasPreviousPage = data?.users.pageInfo.hasPreviousPage

  const memoizedSearchParams = useMemo(() => {
    const params = new URLSearchParams(searchParams.toString())
    if (debouncedQuery !== params.get("q")) {
      if (debouncedQuery === "") {
        params.delete("q")
      } else {
        params.set("q", debouncedQuery)
      }
    }
    if (sort && sort !== defaultSort) {
      params.set("sort", sort)
    } else {
      params.delete("sort")
    }
    if (after) {
      params.set("after", after)
    } else {
      params.delete("after")
    }
    if (before) {
      params.set("before", before)
    } else {
      params.delete("before")
    }

    if (subscriptionStatusFilter) {
      params.set("subscriptionStatus", subscriptionStatusFilter)
    } else {
      params.delete("subscriptionStatus")
    }

    return params
  }, [
    after,
    before,
    searchParams,
    debouncedQuery,
    sort,
    defaultSort,
    subscriptionStatusFilter,
  ])

  useEffect(() => {
    setSearchParams(memoizedSearchParams)
  }, [memoizedSearchParams, setSearchParams])

  useEffect(() => {
    if (debouncedQuery) {
      setAfter(undefined)
      setBefore(undefined)
    }
  }, [debouncedQuery])

  const goToNextPage = () => {
    if (!data?.users.pageInfo.endCursor) return

    setBefore(undefined)
    setAfter(data?.users.pageInfo.endCursor)
  }

  const goToPreviousPage = () => {
    if (!data?.users.pageInfo.startCursor) return

    setAfter(undefined)
    setBefore(data?.users.pageInfo.startCursor)
  }

  const csrfToken = getMetaVar("csrf-token")

  return (
    <>
      {withSearch && (
        <div className="flex justify-center items-center gap-4 mb-4 w-full max-w-[1024px]">
          {withSelectAll && onSelectAll && (
            <div>
              <Button
                onClick={() => onSelectAll(users)}
                size="lg"
                className="text-2xs"
              >
                Select All
              </Button>
            </div>
          )}

          <div className="flex-grow">
            <UsersSearch
              query={query}
              setQuery={setQuery}
              sort={sort}
              setSort={setSort}
            />
          </div>

          {withFilters && (
            <div>
              <SubscriptionStatusFilter
                subscriptionStatusFilter={subscriptionStatusFilter}
                setSubscriptionStatusFilter={setSubscriptionStatusFilter}
              />
            </div>
          )}

          {withExport && (
            <div>
              <form method="POST" action="/users/export.csv">
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={csrfToken}
                />
                <Button type="submit" size="sm" className="!text-2xs h-11">
                  Export All
                </Button>
              </form>
            </div>
          )}
        </div>
      )}

      {error ? (
        <div className="text-center text-red-500 my-16">
          Error loading users.
        </div>
      ) : (
        <>
          {variant === "table" && (
            <UsersTableView
              users={users}
              loading={loading}
              headers={headers!}
              children={children}
              className={className}
            />
          )}
          {variant === "card" && (
            <UsersCardView
              users={users}
              loading={loading}
              children={children}
              skeleton={skeleton}
              className={className}
            />
          )}
        </>
      )}
      <div className="my-4 w-full">
        <Pagination>
          <PaginationContent className="flex w-full justify-between">
            <PaginationItem>
              {hasPreviousPage && (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={goToPreviousPage}
                  disabled={!hasPreviousPage}
                >
                  <div className="flex items-center gap-2">
                    <ChevronLeft className="w-5 h-5" />
                    <span>Previous</span>
                  </div>
                </Button>
              )}
            </PaginationItem>
            <PaginationItem>
              {hasNextPage && (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={goToNextPage}
                  disabled={!hasNextPage}
                >
                  <div className="flex items-center gap-2">
                    <span>Next</span>
                    <ChevronRight className="w-5 h-5" />
                  </div>
                </Button>
              )}
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </>
  )
}

type UsersTableViewProps = {
  users: User_AdminFragment[]
  loading: boolean
  headers: AdminTableHeader[]
  children(user: User_AdminFragment): React.ReactNode
  className: string
}
type UsersCardViewProps = Omit<UsersTableViewProps, "headers"> & {
  skeleton?: React.ReactNode
}

const UsersCardView = ({
  users,
  loading,
  children,
  skeleton,
  className,
}: UsersCardViewProps) => {
  return (
    <>
      {loading && skeleton}
      {!loading && users.length > 0 && (
        <div className={cn("flex flex-col gap-2", className)}>
          {users.map(children)}
        </div>
      )}
      {!loading && users.length === 0 && (
        <div className="text-center text-gray-500">No users found.</div>
      )}
    </>
  )
}

const UsersTableView = ({
  users,
  loading,
  headers,
  children,
  className,
}: UsersTableViewProps) => {
  return (
    <>
      {!loading && users.length > 0 && (
        <Table>
          {headers && (
            <TableHeader>
              <TableRow className="group">
                {headers.map((header) => (
                  <TableHead
                    key={header.label}
                    className={cn(header.isPinned && pinnedColumnStyles)}
                  >
                    <div className={cn(header.isPinned && "pr-8")}>
                      {header.label}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
          )}
          <TableBody>{users.map(children)}</TableBody>
        </Table>
      )}
    </>
  )
}

gql(`
  fragment User_Admin on User {
    id
    admin
    firstName
    lastName
    email
    phone
    createdAt
    updatedAt
    stripeCustomerId

    firstIssue {
      id
      title
      number
      fulfillmentStart
    }

    shippingAddress {
      id
      line1
      line2
      city
      state
      postalCode
      country
    }

    stripeSubscription {
      id
      stripeSubscriptionId
      status
      currentPeriodEnd
      currentPeriodStart
      cancelAtPeriodEnd
      canceledAt
      endedAt
      startDate
      createdAt
      updatedAt

      subscriptionPlan {
        id
        name
        unitAmountFormatted
        interval
        intervalCount
      }
    }
  }
`)

export const USERS_QUERY_DOCUMENT = gql(`
  query AdminUsers(
    $first: Int,
    $last: Int,
    $before: String,
    $after: String,
    $sort: UserSortEnum,
    $query: String,
    $subscriptionStatus: StripeSubscriptionStatusEnum,
  ) {
    users(
      first: $first,
      last: $last,
      before: $before,
      after: $after,
      sort: $sort,
      query: $query,
      subscriptionStatus: $subscriptionStatus,
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...User_Admin
      }
    }
  }
`)
