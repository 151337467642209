import { UserAddress } from "~/__generated__/graphql"

type ShippingAddress = Pick<
  UserAddress,
  | "name"
  | "line1"
  | "line2"
  | "city"
  | "state"
  | "country"
  | "postalCode"
  | "phone"
>

export const Address = ({ address }: { address?: ShippingAddress }) =>
  address && (
    <div>
      {[
        address.name,
        address.line1,
        address.line2,
        [
          [address.city, address.state, address.country]
            .filter((l) => !!l)
            .join(", "),
          address.postalCode,
        ]
          .filter((l) => !!l)
          .join(" "),
        address.phone,
      ]
        .filter((l) => !!l)
        .map((line, i) => (
          <div key={i}>{line}</div>
        ))}
    </div>
  )
