import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { signInTokenPath, subscribePath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent, CardHeader } from "~/shadcn/ui/card"
import Logo from "~/images/logo.svg?react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "~/shadcn/ui/form"
import { Input } from "~/shadcn/ui/input"
import { Link } from "~/ui/Link"
import { typographyVariants } from "~/ui/Typography"

const SEND_MAGIC_LINK_MUTATION = gql(`
  mutation SendMagicLink($input: SendMagicLinkInput!) {
    sendMagicLink(input: $input) {
      sessionId
    }
  }
`)

const signInFormSchema = z.object({
  email: z.string().min(1, {
    message: "Email is required",
  }),
})

export const SignInScreen = () => {
  const [sendMagicLink, { loading }] = useSafeMutation(SEND_MAGIC_LINK_MUTATION)
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof signInFormSchema>>({
    resolver: zodResolver(signInFormSchema),
    defaultValues: {
      email: "",
    },
  })

  const onSubmit = async (values: z.infer<typeof signInFormSchema>) => {
    const { data } = await sendMagicLink({
      variables: {
        input: {
          email: values.email,
        },
      },
    })

    if (data?.sendMagicLink.sessionId) {
      toast.success(
        "Magic link sent.  Click the link in the email or enter the token here."
      )
      navigate(signInTokenPath({ sessionId: data.sendMagicLink.sessionId }), {
        state: { email: values.email },
      })
    } else if (data?.sendMagicLink) {
      toast.error("Account not found")
      form.setError(
        "email",
        {
          type: "custom",
          message: "Account not found",
        },
        { shouldFocus: true }
      )
    } else {
      toast.error("Error sending magic link")
    }
  }

  return (
    <Card className="sm:w-[440px] m-auto">
      <CardHeader className="flex justify-center items-center">
        <Logo className="w-[160px] h-[20px] fill-gray-333" />
      </CardHeader>
      <CardContent className="flex flex-col justify-center items-center text-center gap-6">
        <div className={typographyVariants({ variant: "h4" })}>
          Provide your email and we will send you a magic link to sign in.
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-7 justify-center items-center"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full flex-col gap-7">
                  <FormControl>
                    <Input placeholder="Email address" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              size="lg"
              disabled={loading || !form.formState.isValid}
              type="submit"
            >
              Get Magic Link
            </Button>

            <p className="text-xs text-gray-333">
              By clicking “Get Magic Link” you agree <br />
              with our{" "}
              <Link
                className="whitespace-nowrap"
                to="https://racquetmag.com/legal/"
                rel="nofollow"
                target="_blank"
              >
                Terms of Service
              </Link>
              .
            </p>

            <p>
              Not a member yet? <Link to={subscribePath.pattern}>Sign up</Link>
            </p>
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}
