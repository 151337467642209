import { useLayoutEffect, useRef } from "react"
import { cn } from "~/common/shadcn-utils"

export const Embed = ({
  className,
  children,
}: {
  className?: string
  children: string
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const range = document.createRange()
    range.selectNode(ref.current!)
    const fragment = range.createContextualFragment(children)

    ref.current!.innerHTML = ""
    ref.current!.append(fragment)
  })

  return (
    <div
      ref={ref}
      className={cn(className)}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}
