import * as React from "react"

import { cn } from "~/common/shadcn-utils"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  prefixIcon?: React.ReactNode
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, prefixIcon, ...props }, ref) => {
    return (
      <div className="h-11 w-full border border-slate-200 bg-white relative">
        {prefixIcon && (
          <div className="flex items-center justify-center w-11 h-11 absolute left-0 top-0 text-placeholder">
            {prefixIcon}
          </div>
        )}
        <input
          type={type}
          className={cn(
            "relative w-full h-11 flex px-3 py-2 text-sm border-0 bg-transparent ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-placeholder placeholder:italic focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            prefixIcon ? "pl-11" : "",
            className
          )}
          ref={ref}
          {...props}
        />
      </div>
    )
  }
)

Input.displayName = "Input"

export { Input }
