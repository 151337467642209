import { CountryCode, parsePhoneNumber } from "libphonenumber-js"

export default function formatPhoneNumber(
  phoneNumber: string,
  countryCode: string = "US"
): string {
  try {
    return parsePhoneNumber(
      phoneNumber,
      countryCode as CountryCode
    )?.formatInternational()
  } catch (error) {
    if (countryCode === "US") {
      return phoneNumber
    } else {
      return formatPhoneNumber(phoneNumber, "US")
    }
  }
}
