import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { adminUsersPath } from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"

export const RequireNotAdmin = () => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUserMaybe()

  useEffect(() => {
    if (currentUser?.admin === true) {
      navigate(adminUsersPath.pattern)
    }
  }, [currentUser, navigate])

  if (currentUser?.admin === true) {
    return null
  }

  return <Outlet />
}
