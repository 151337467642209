import { Search } from "lucide-react"
import { UserSortEnum } from "~/__generated__/graphql"
import { Input } from "~/shadcn/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/shadcn/ui/select"

type UsersSearchProps = {
  query: string
  setQuery: (query: string) => void
  sort: UserSortEnum
  setSort: (sort: UserSortEnum) => void
}

export const UsersSearch = ({
  query,
  setQuery,
  sort,
  setSort,
}: UsersSearchProps) => {
  return (
    <div className="flex gap-2">
      <div className="flex-grow">
        <Input
          prefixIcon={<Search className="w-4 h-4" />}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <div>
        <Select
          onValueChange={(value) => setSort(value as UserSortEnum)}
          value={sort}
        >
          <SelectTrigger className="text-sm min-w-[162px]">
            <SelectValue placeholder="" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={UserSortEnum.Recent}>Recent</SelectItem>
            <SelectItem value={UserSortEnum.Name}>Name (A-Z)</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}
