import { format, parseISO } from "date-fns"

export const toCalendarDate = (date: Date) => {
  const timezoneOffset = date.getTimezoneOffset()
  return new Date(date.getTime() + timezoneOffset * 60000)
}

// Return the date on the same calendar day, regardless of timezone
export const formatCalendarDate = (
  date: string | Date,
  pattern: string = "PPP"
) => {
  if (date instanceof Date) {
    return format(toCalendarDate(date), pattern)
  }
  return format(parseISO(date), pattern)
}
