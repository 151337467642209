import { Outlet } from "react-router-dom"
import { Hero } from "~/ui/Hero"
import { Navigation } from "./elements/Navigation"
import { createContext, useContext, useState } from "react"
import { cn } from "~/common/shadcn-utils"
import { typographyVariants } from "~/ui/Typography"
import { Footer } from "./elements/Footer"

type LandingContextType = {
  setTitle: (title: string) => void
}
const LandingContext = createContext<LandingContextType>({
  setTitle: (title: string) => {},
})
export const useLandingContext = () => {
  return useContext(LandingContext)
}

export const LandingLayout = () => {
  const [title, setTitle] = useState("")
  return (
    <LandingContext.Provider value={{ setTitle }}>
      <Navigation variant="follower" />
      <Hero variant="court" size="vignette" fixedUntilScroll>
        {title && (
          <h1
            className={cn("text-white", typographyVariants({ variant: "h1" }))}
          >
            {title}
          </h1>
        )}
      </Hero>
      <Outlet />
      <Footer />
    </LandingContext.Provider>
  )
}
