import { useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { InputMaybe, PageInfo, Scalars } from "~/__generated__/graphql"

type PaginationQueryVariables = {
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  after?: InputMaybe<Scalars["String"]["input"]>
}

export const usePagination = ({
  pageInfo,
  paramPrefix = "",
  perPage,
}: {
  pageInfo?: PageInfo
  paramPrefix?: string
  perPage: number
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const key = useCallback((k: string) => `${paramPrefix}${k}`, [paramPrefix])

  const [after, setAfter] = useState<string | undefined>(
    searchParams.get(key("after")) || undefined
  )
  const [before, setBefore] = useState<string | undefined>(
    searchParams.get(key("before")) || undefined
  )
  const first = useMemo(() => {
    if (!before) return perPage
    return undefined
  }, [before, perPage])
  const last = useMemo(() => {
    if (before) return perPage
    return undefined
  }, [before, perPage])

  const queryOptions = useMemo(() => {
    const options: PaginationQueryVariables = {}
    if (first) options.first = first
    if (last) options.last = last
    if (before) options.before = before
    if (after) options.after = after
    if (Object.keys(options).length === 0) return {}
    return options
  }, [first, last, before, after])

  useEffect(() => {
    if (after) {
      searchParams.set(key("after"), after)
    } else {
      searchParams.delete(key("after"))
    }
    if (before) {
      searchParams.set(key("before"), before)
    } else {
      searchParams.delete(key("before"))
    }
    setSearchParams(searchParams)
  }, [after, before, searchParams, setSearchParams, key])

  const hasNextPage = !!pageInfo?.hasNextPage
  const hasPreviousPage = !!pageInfo?.hasPreviousPage
  const goToNextPage = useCallback(() => {
    if (!pageInfo?.endCursor) return

    setBefore(undefined)
    setAfter(pageInfo.endCursor)
  }, [pageInfo])

  const goToPreviousPage = useCallback(() => {
    if (!pageInfo?.startCursor) return

    setAfter(undefined)
    setBefore(pageInfo.startCursor)
  }, [pageInfo])

  return {
    queryOptions,
    hasNextPage,
    hasPreviousPage,
    goToNextPage,
    goToPreviousPage,
  }
}
