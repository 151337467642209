import { VariantProps, cva } from "class-variance-authority"
import * as React from "react"
import { cn } from "~/common/shadcn-utils"

export const NavBarVariants = cva(
  "w-full h-16 flex justify-between items-center p-4",
  {
    variants: {
      variant: {
        default: "bg-transparent absolute top-0 left-0 z-50",
        follower:
          "fixed z-50 top-0 bg-transparent transition-all duration-300 text-white",
        followerScrolled:
          "fixed z-50 top-0 bg-white text-foreground shadow-md transition-all duration-300",
      },
      size: {
        default: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

type ChildrenWithVariant = {
  children: (variant: NavBarProps["variant"]) => React.ReactNode
}

interface NavBarProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "children">,
    ChildrenWithVariant,
    VariantProps<typeof NavBarVariants> {}

export const NavBar = React.forwardRef<HTMLDivElement, NavBarProps>(
  ({ variant, size, className, children, ...props }, ref) => {
    const [currentVariant, setCurrentVariant] = React.useState<
      NavBarProps["variant"]
    >(variant || "default")

    React.useEffect(() => {
      const handleScroll = () => {
        if (variant !== "follower") {
          return
        }

        if (window.scrollY > 0) {
          setCurrentVariant("followerScrolled")
        } else {
          setCurrentVariant("follower")
        }
      }

      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }, [variant])

    return (
      <div
        className={cn(
          NavBarVariants({ variant: currentVariant, size, className })
        )}
        ref={ref}
        {...props}
      >
        {children(currentVariant)}
      </div>
    )
  }
)
