import { CheckIcon } from "@radix-ui/react-icons"

export const MarketingFeatures = ({ features }: { features: string[] }) => {
  return (
    <ul className="flex flex-col gap-2">
      {features.map((feature, i) => (
        <li key={i} className="flex gap-2">
          <CheckIcon className="w-6 h-6 text-green-600" /> {feature}
        </li>
      ))}
    </ul>
  )
}
