import { Outlet } from "react-router-dom"
import { Hero } from "~/ui/Hero"
import { Navigation } from "./elements/Navigation"

export const HeroLayout = () => {
  return (
    <>
      <Navigation variant="follower" />
      <Hero variant="net" size="full" className="fixed">
        <Outlet />
      </Hero>
    </>
  )
}
