import { QueryResult, useApolloClient, useQuery } from "@apollo/client"
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react"
import invariant from "tiny-invariant"
import { CurrentUserProviderQuery, Exact } from "~/__generated__/graphql"
import { CURRENT_USER_QUERY_DOCUMENT } from "./currentUserQuery"
import { Base64 } from "js-base64"
import { GraphqlError } from "~/components/GraphqlError"
import * as Sentry from "@sentry/react"

interface CurrentUserContextType {
  result: QueryResult<
    CurrentUserProviderQuery,
    Exact<{
      [key: string]: never
    }>
  >
  currentUser: CurrentUserProviderQuery["currentUser"]
}

const CurrentUserContext = createContext<CurrentUserContextType | null>(null)

const metaTag = document.querySelector("meta[name=current-user-cache]")
const base64CurrentUserData = metaTag?.getAttribute("content")
const userData = base64CurrentUserData
  ? JSON.parse(Base64.decode(base64CurrentUserData))
  : null
if (userData?.data?.currentUser?.id && userData?.data?.currentUser?.email) {
  Sentry.setUser({
    id: userData.data.currentUser.id,
    email: userData.data.currentUser.email,
  })
}

export const CurrentUserProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const result = useQuery(CURRENT_USER_QUERY_DOCUMENT)
  const currentUser = result.data?.currentUser
  const cachePreloadedRef = useRef(false)
  const client = useApolloClient()

  useEffect(() => {
    if (cachePreloadedRef.current) return
    cachePreloadedRef.current = true

    if (!userData.data) {
      console.log("Error preloading current user")
      return
    }

    client.writeQuery({
      query: CURRENT_USER_QUERY_DOCUMENT,
      data: userData.data,
      variables: {},
    })
  }, [client])

  const value = useMemo(() => ({ result, currentUser }), [result, currentUser])

  useEffect(() => {
    if (currentUser) {
      Sentry.setUser({
        id: currentUser.id,
        email: currentUser.email,
      })
    } else if (!result.loading) {
      Sentry.setUser(null)
    }
  }, [currentUser, result.loading])

  if (result.error) return <GraphqlError error={result.error} />
  if (result.loading) return null
  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUserMaybe = () => {
  const contextValue = useContext(CurrentUserContext)
  if (contextValue === null) {
    throw Error("Context has not been Provided!")
  }
  return contextValue
}

export const useCurrentUser = () => {
  const contextValue = useContext(CurrentUserContext)
  invariant(contextValue, "Context has not been Provided!")
  invariant(contextValue.currentUser, "User must be logged in")
  return contextValue.currentUser
}
