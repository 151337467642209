import { Link } from "~/ui/Link"
import InstagramLogoIcon from "~/images/icons/instagram.svg?react"
import XLogoIcon from "~/images/icons/x.svg?react"

export const Footer = () => {
  return (
    <div className="p-4 md:h-24 bg-white text-gray-999 text-sm mt-auto">
      <div className="container mx-auto flex flex-col gap-4 flex-col-reverse md:flex-row items-center md:justify-between md:h-full">
        <div className="flex gap-4">
          <Link
            href="https://twitter.com/racquetmagazine"
            target="_blank"
            rel="noreferrer noopener"
          >
            <XLogoIcon className="w-5 h-5" />
          </Link>
          <Link
            href="https://www.instagram.com/racquetmag"
            target="_blank"
            rel="noreferrer noopener"
          >
            <InstagramLogoIcon className="w-5 h-5" />
          </Link>
          <div>
            ©️ {new Date().getFullYear()} Racquet Publishing, LLC. All Rights
            Reserved.
          </div>
        </div>
        <div className="flex gap-8">
          <Link variant="nav" href="https://racquetmag.com/racquet-faq/">
            FAQ
          </Link>
          <Link variant="nav" href="https://racquetmag.com/newsletter/">
            Newsletter
          </Link>
          <Link variant="nav" href="https://shop.racquetmag.com/">
            Shop
          </Link>
          <Link variant="nav" href="https://racquetmag.com/legal/">
            Legal
          </Link>
        </div>
      </div>
    </div>
  )
}
