import { cva } from "class-variance-authority"

export const typographyVariants = cva("", {
  variants: {
    variant: {
      h1: "text-5xl sm:text-6xl md:text-7xl lg:text-8xl tracking-tight font-display font-normal",
      h2: "text-4xl sm:text-5xl md:text-6xl lg:text-7xl tracking-tight font-display font-normal",
      h3: "text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-display font-normal",
      h4: "text-lg sm:text-xl md:text-2xl lg:text-3xl font-display font-normal",
      h5: "text-sm sm:text-md md:text-lg lg:text-xl font-display font-normal",
      h6: "text-xs sm:text-sm md:text-md lg:text-lg font-display font-normal",
      highlight:
        "text-sm sm:text-xs md:text-sm lg:text-md font-display font-normal",
      body: "text-base",
      caption: "text-sm",
      cardType: "font-display-alternate uppercase text-primary",
    },
  },
})
