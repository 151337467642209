import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query

export const CURRENT_USER_QUERY_DOCUMENT = gql(`
  query CurrentUserProvider {
    currentUser {
      id
      admin
      email
      phone
      firstName
      lastName
      shippingAddress {
        id
        line1
        line2
        city
        state
        postalCode
        country
        name
        phone
      }
      stripeSubscription {
        currentPeriodEnd

        subscriptionPlan {
          id
          name
          unitAmountFormatted
          interval
          intervalCount
          marketingFeatures
        }
      }
      __typename
    }
  }
`)
