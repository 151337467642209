import { useMediaQuery } from "usehooks-ts"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "~/shadcn/ui/dialog"
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from "~/shadcn/ui/drawer"
import { IssueForm } from "./IssueForm"
import { Issue_AdminFragment } from "~/__generated__/graphql"
import { useCallback } from "react"

interface IssueDialogProps {
  issue?: Issue_AdminFragment
  open: boolean
  setOpen: (open: boolean) => void
  onSaved: (issue: Issue_AdminFragment) => void
}

export const IssueDialog = ({
  issue,
  open,
  setOpen,
  onSaved,
}: IssueDialogProps) => {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  const handleSave = useCallback(
    (issue: Issue_AdminFragment) => {
      setOpen(false)
      onSaved(issue)
    },
    [onSaved, setOpen]
  )

  const handleCancel = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{issue ? "Edit" : "Create"} Issue</DialogTitle>
            <DialogDescription>
              {issue
                ? "Make changes to the issue here."
                : "Fill out the form below to create a new issue."}
            </DialogDescription>
          </DialogHeader>
          <IssueForm
            onSaved={handleSave}
            onCancel={handleCancel}
            issue={issue}
          />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerContent>
        <DrawerHeader className="text-left">
          <DrawerTitle>{issue ? "Edit" : "Create"} Issue</DrawerTitle>
          <DrawerDescription>
            {issue
              ? "Make changes to the issue here."
              : "Fill out the form below to create a new issue."}
          </DrawerDescription>
        </DrawerHeader>
        <div className="p-4">
          <IssueForm
            onSaved={handleSave}
            onCancel={handleCancel}
            issue={issue}
          />
        </div>
      </DrawerContent>
    </Drawer>
  )
}
