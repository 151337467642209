import { VariantProps, cva } from "class-variance-authority"
import { typographyVariants } from "./Typography"
import { cn } from "~/common/shadcn-utils"
import { Types as ImageTypes } from "~/images"
import { BackgroundImage } from "./BackgroundImage"

const calloutVariants = cva("sm:flex gap-2 items-center sm:h-[400px]", {
  variants: {
    alignment: {
      left: "flex-row-reverse",
      right: "",
    },
  },
  defaultVariants: {},
})

type CalloutProps = {
  image: ImageTypes
  children: React.ReactNode | string
} & VariantProps<typeof calloutVariants>

export const Callout = ({ alignment, image, children }: CalloutProps) => {
  return (
    <div className={cn(calloutVariants({ alignment }))}>
      <div className="flex-grow sm:w-1/2 sm:h-full h-[174px] w-full">
        <BackgroundImage
          className="bg-gradient-to-br from-slate-100 to-slate-200 w-full h-full bg-cover bg-center"
          image={image}
        />
      </div>

      <div
        className={cn(
          "sm:flex sm:flex-grow sm:w-1/2 sm:h-full items-center",
          alignment === "left" && "justify-end"
        )}
      >
        <div className="max-w-[500px] p-16">
          <h5 className={typographyVariants({ variant: "h3" })}>{children}</h5>
        </div>
      </div>
    </div>
  )
}
