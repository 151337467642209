import { VariantProps, cva } from "class-variance-authority"
import * as React from "react"

import { cn } from "~/common/shadcn-utils"
import { typographyVariants } from "~/ui/Typography"

// card variants
const cardVariants = cva(
  "rounded-lg border border-slate-200 bg-white text-slate-950 shadow-sm",
  {
    variants: {
      variant: {
        noBorder: "border-none shadow-none bg-transparent",
        subscription: "border-neutral-400 rounded-xl",
      },
      heft: {
        default: "",
        light: "",
        medium: "",
        heavy: "",
      },
    },
    defaultVariants: {
      heft: "default",
    },
  }
)

const CardContext = React.createContext<{
  variant: VariantProps<typeof cardVariants>["variant"]
  heft: VariantProps<typeof cardVariants>["heft"]
}>({ variant: undefined, heft: undefined })

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardVariants>
>(({ className, variant, heft, ...props }, ref) => (
  <CardContext.Provider value={{ variant, heft: heft || "default" }}>
    <div
      ref={ref}
      className={cn(cardVariants({ variant, className }))}
      {...props}
    />
  </CardContext.Provider>
))
Card.displayName = "Card"

const cardHeaderVariants = cva("flex flex-col space-y-1.5", {
  variants: {
    cardVariant: {
      noBorder: "",
      subscription: "border-b border-neutral-400",
    },
    heft: {
      default: "p-11",
      light: "p-4",
      medium: "p-7",
      heavy: "p-11",
    },
  },
  defaultVariants: {},
})

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardHeaderVariants>
>(({ className, ...props }, ref) => {
  const { variant: cardVariant, heft } = React.useContext(CardContext)

  return (
    <div
      ref={ref}
      className={cn(cardHeaderVariants({ cardVariant, heft }), className)}
      {...props}
    />
  )
})
CardHeader.displayName = "CardHeader"

const cardTitleVariants = cva(
  "text-2xl font-semibold leading-none tracking-tight",
  {
    variants: {
      cardVariant: {
        noBorder: "",
        subscription: "",
      },
    },
    defaultVariants: {},
  }
)

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement> &
    VariantProps<typeof cardTitleVariants> &
    VariantProps<typeof typographyVariants>
>(({ className, children, variant, ...props }, ref) => {
  const { variant: cardVariant } = React.useContext(CardContext)
  return (
    <h3
      ref={ref}
      className={cn(
        typographyVariants({ variant: variant || "h3" }),
        cardTitleVariants({ cardVariant }),
        className
      )}
      aria-label="Card title"
      {...props}
    >
      {children}
    </h3>
  )
})
CardTitle.displayName = "CardTitle"

const cardDescriptionVariants = cva("text-sm text-slate-500", {
  variants: {
    cardVariant: {
      noBorder: "",
      subscription: "",
    },
  },
  defaultVariants: {},
})

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement> &
    VariantProps<typeof cardDescriptionVariants>
>(({ className, ...props }, ref) => {
  const { variant: cardVariant } = React.useContext(CardContext)

  return (
    <p
      ref={ref}
      className={cn(cardDescriptionVariants({ cardVariant }), className)}
      {...props}
    />
  )
})
CardDescription.displayName = "CardDescription"

const cardContentVariants = cva("pt-0", {
  variants: {
    cardVariant: {
      noBorder: "",
      subscription: "bg-primary/10 !pt-4",
    },
    heft: {
      default: "p-11 pt-0",
      light: "p-4 pt-0",
      medium: "p-7 pt-0",
      heavy: "p-11 pt-0",
    },
  },
  defaultVariants: {},
})

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { variant: cardVariant, heft } = React.useContext(CardContext)
  return (
    <div
      ref={ref}
      className={cn(cardContentVariants({ cardVariant, heft }), className)}
      {...props}
    />
  )
})
CardContent.displayName = "CardContent"

const cardFooterVariants = cva("flex items-center pt-0", {
  variants: {
    cardVariant: {
      noBorder: "",
      subscription: "",
    },
    heft: {
      default: "p-11",
      light: "p-4",
      medium: "p-7",
      heavy: "p-11",
    },
  },
  defaultVariants: {},
})

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { variant: cardVariant, heft } = React.useContext(CardContext)
  return (
    <div
      ref={ref}
      className={cn(cardFooterVariants({ cardVariant, heft }), className)}
      {...props}
    />
  )
})
CardFooter.displayName = "CardFooter"

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent }
