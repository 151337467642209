import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { formatDate } from "date-fns"
import { useRef } from "react"
import { User_AdminFragment } from "~/__generated__/graphql"
import { Badge } from "~/shadcn/ui/badge"
import { Button } from "~/shadcn/ui/button"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/shadcn/ui/context-menu"
import { TableCell, TableRow } from "~/shadcn/ui/table"
import { UsersTable } from "./UsersTable"
import { Address } from "../Address"
import { Link } from "~/ui/Link"
import { ExternalLink } from "lucide-react"
import formatPhoneNumber from "~/common/formatPhoneNumber"
import { AdminTableHeader } from "."
import { formatCalendarDate } from "~/common/formatCalendarDate"

const HEADERS: AdminTableHeader[] = [
  { label: "Name", isPinned: true },
  { label: "Date Joined" },
  { label: "Phone" },
  { label: "Shipping Address" },
  { label: "Starting Issue" },
  { label: "Stripe Customer" },
  { label: "Stripe Subscription" },
  { label: "Actions" },
]

const pinnedColumnStyles =
  "sticky left-0 bg-gradient-to-r from-white from-80% to-transparent group-hover:from-muted group-hover:from-80% group-hover:to-transparent"

const UserRow = ({ user }: { user: User_AdminFragment }) => {
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)
  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  const handleViewStripeCustomer = () => {
    if (user.stripeCustomerId) {
      window.open(
        `https://dashboard.stripe.com/customers/${user.stripeCustomerId}`,
        "_blank"
      )
    }
  }

  const handleViewStripeSubscription = () => {
    if (user.stripeSubscription) {
      window.open(
        `https://dashboard.stripe.com/subscriptions/${user.stripeSubscription.stripeSubscriptionId}`,
        "_blank"
      )
    }
  }

  return (
    <>
      <ContextMenu>
        <ContextMenuContent>
          <ContextMenuItem
            className="cursor-pointer"
            onClick={handleViewStripeCustomer}
            disabled={!user.stripeCustomerId}
          >
            View Stripe Customer
            <ExternalLink className="w-4 h-4 ml-2" />
          </ContextMenuItem>
          <ContextMenuItem
            className="cursor-pointer"
            onClick={handleViewStripeSubscription}
            disabled={!user.stripeSubscription}
          >
            View Stripe Subscription
            <ExternalLink className="w-4 h-4 ml-2" />
          </ContextMenuItem>
          <ContextMenuItem className="cursor-pointer" asChild>
            <Link href={`/users/${user.id}/impersonate`} method="POST">
              Impersonate
            </Link>
          </ContextMenuItem>
        </ContextMenuContent>
        <ContextMenuTrigger asChild>
          <TableRow ref={contextMenuTriggerRef} className="group">
            <TableCell className={pinnedColumnStyles}>
              <div className="pr-8 flex flex-row gap-2 items-center">
                {user.admin && <Badge variant="secondary">Admin</Badge>}
                <div>
                  {user.firstName} {user.lastName}
                </div>
              </div>
              <div>
                <Link href={`mailto:${user.email}`}>{user.email}</Link>
              </div>
            </TableCell>
            <TableCell>{formatDate(user.createdAt, "MMM d, yyyy")}</TableCell>
            <TableCell>{user.phone && formatPhoneNumber(user.phone)}</TableCell>
            <TableCell>
              {user.shippingAddress && (
                <Address address={user.shippingAddress} />
              )}
            </TableCell>
            <TableCell>
              {user.firstIssue && (
                <>
                  <div>{user.firstIssue.title}</div>
                  <div>
                    {user.firstIssue.number},{" "}
                    {formatCalendarDate(user.firstIssue.fulfillmentStart)}
                  </div>
                </>
              )}
            </TableCell>
            <TableCell>
              {user.stripeCustomerId && (
                <Link
                  to={`https://dashboard.stripe.com/customers/${user.stripeCustomerId}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Badge>
                    {user.stripeCustomerId}
                    <ExternalLink className="w-4 h-4 ml-2" />
                  </Badge>
                </Link>
              )}
            </TableCell>
            <TableCell>
              {user.stripeSubscription && (
                <Link
                  to={`https://dashboard.stripe.com/subscriptions/${user.stripeSubscription.stripeSubscriptionId}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Badge>
                    {user.stripeSubscription.stripeSubscriptionId}
                    <ExternalLink className="w-4 h-4 ml-2" />
                  </Badge>
                </Link>
              )}
            </TableCell>
            <TableCell>
              <Button variant="ghost" size="icon" onClick={triggerContextMenu}>
                <DotsHorizontalIcon />
              </Button>
            </TableCell>
          </TableRow>
        </ContextMenuTrigger>
      </ContextMenu>
    </>
  )
}

export const AllUsersTable = () => {
  return (
    <UsersTable withFilters withSearch withExport headers={HEADERS}>
      {(user) => <UserRow key={user.id} user={user} />}
    </UsersTable>
  )
}
