import * as Images from "~/images"

interface BackgroundImageProps extends React.HTMLAttributes<HTMLDivElement> {
  image: Images.Types
}

export const BackgroundImage = ({ image, ...props }: BackgroundImageProps) => {
  // iterate over all exports from Images to find those that start with the image prop
  const matchingImageFormats = Object.entries(Images).filter(([key]) =>
    key.startsWith(image)
  )

  const imageSet =
    "image-set(" +
    matchingImageFormats
      .map(([key, value]) => {
        return {
          resolution: parseInt(key.match(/(\d+)x/)?.[1] || "0"),
          type: key.includes("Webp") ? "image/webp" : "image/png",
          url: value,
        }
      })
      .sort((a, b) => (a.resolution || 0) - (b.resolution || 0))
      .sort((a, b) => (a.type === "image/webp" ? -1 : 1))
      .map(({ url, type }) => {
        return `url(${url}) type('${type}')`
      })
      .join(", ") +
    ")"

  return <div {...props} style={{ backgroundImage: imageSet }} />
}
