import { useEffect } from "react"
import toast from "react-hot-toast"
import { Outlet, useNavigate } from "react-router-dom"
import { signInPath } from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"

export const RequireUserActiveSubscription = () => {
  const navigate = useNavigate()
  const { currentUser, result } = useCurrentUserMaybe()

  useEffect(() => {
    if (
      !currentUser?.stripeSubscription &&
      !currentUser?.admin &&
      !result.loading
    ) {
      toast.error("An active subscription is required to access this page.")
      navigate(signInPath.pattern)
    }
  }, [currentUser, result.loading, navigate])

  if (result.loading) {
    return null
  }

  if (!currentUser?.stripeSubscription && !currentUser?.admin) {
    return null
  }

  return <Outlet />
}
