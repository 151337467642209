import { useQuery } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import { gql } from "~/__generated__"
import { Issue_PublicFragment, PageInfo } from "~/__generated__/graphql"
import { cn } from "~/common/shadcn-utils"
import { usePageTitle } from "~/common/usePageTitle"
import { usePagination } from "~/common/usePagination"
import { Embed } from "~/components/Embed"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent } from "~/shadcn/ui/card"
import { typographyVariants } from "~/ui/Typography"

export const DigitalArchiveScreen = () => {
  usePageTitle("Digital Archive")

  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(undefined)

  const {
    queryOptions,
    hasNextPage,
    hasPreviousPage,
    goToNextPage,
    goToPreviousPage,
  } = usePagination({ pageInfo, perPage: 9 })
  const { data, loading } = useQuery(ISSUES_QUERY_DOCUMENT, {
    variables: { ...queryOptions, readableOnly: true, libraryOnly: true },
  })

  useEffect(() => {
    if (data?.issues.pageInfo) {
      setPageInfo(data.issues.pageInfo)
    }
  }, [data])
  const issues = useMemo(
    () =>
      data?.issues.edges.map((edge) => edge.node as Issue_PublicFragment) || [],
    [data]
  )

  return (
    <div className="container mx-auto flex flex-col items-center gap-16">
      <h1
        className={cn(
          typographyVariants({ variant: "h2" }),
          "mt-16 text-center"
        )}
      >
        Digital Archive
      </h1>
      {loading && (
        <div className="w-full h-96 flex items-center justify-center">
          <LoadingIndicatorCentered />
        </div>
      )}
      {!loading && issues.length === 0 && (
        <Card className="max-w-96 w-full">
          <CardContent className="pt-11 h-32 w-full flex items-center justify-center">
            <h6 className={typographyVariants({ variant: "h6" })}>
              No issues found.
            </h6>
          </CardContent>
        </Card>
      )}

      {!loading && issues.length > 0 && (
        <div className="flex flex-col gap-16 mb-16 w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-16 w-full">
            {issues.map((issue) => (
              <div
                key={issue.id}
                className="flex flex-col gap-2 items-center justify-center"
              >
                <Embed className="h-[188px] flex items-center justify-center">
                  {issue.embedCode!}
                </Embed>
                <div className={typographyVariants({ variant: "h4" })}>
                  {issue.title}
                </div>
              </div>
            ))}
          </div>
          {(hasPreviousPage || hasNextPage) && (
            <div className="flex justify-between items-center">
              <div>
                {hasPreviousPage && (
                  <Button onClick={goToPreviousPage}>Previous</Button>
                )}
              </div>
              <div>
                {hasNextPage && <Button onClick={goToNextPage}>Next</Button>}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

gql(`
  fragment Issue_Public on Issue {
    id
    title
    number
    embedCode
    availableInLibrary
    canRead { value }
  }
`)

const ISSUES_QUERY_DOCUMENT = gql(`
  query Issues($first: Int, $after: String, $before: String, $last: Int, $readableOnly: Boolean, $libraryOnly: Boolean) {
    issues(first: $first, after: $after, before: $before, last: $last, readableOnly: $readableOnly, libraryOnly: $libraryOnly) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...Issue_Public
        }
      }
    }
  }
`)
