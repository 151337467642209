import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react"
import { Issue_AdminFragment } from "~/__generated__/graphql"
import { Badge } from "~/shadcn/ui/badge"
import { Button } from "~/shadcn/ui/button"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/shadcn/ui/context-menu"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/ui/table"
import { gql } from "~/__generated__"
import { LoadingIndicatorCentered } from "../LoadingIndicator"
import { Error } from "~/ui/Error"
import { AdminTableHeader } from "."
import { Card, CardContent } from "~/shadcn/ui/card"
import { typographyVariants } from "~/ui/Typography"
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from "~/shadcn/ui/pagination"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { IssueDialog } from "../IssueDialog"
import invariant from "tiny-invariant"
import { formatCalendarDate } from "~/common/formatCalendarDate"

interface IssuesContextProps {
  loading: boolean
  error?: Error
  issues: Issue_AdminFragment[]
  setIssues: Dispatch<SetStateAction<Issue_AdminFragment[]>>
  hasNextPage: boolean
  hasPreviousPage: boolean
  goToNextPage: () => void
  goToPreviousPage: () => void
}

const IssuesContext = createContext<IssuesContextProps | null>(null)

export const IssuesProvider = ({
  children,
  ...contextValues
}: { children: React.ReactNode } & IssuesContextProps) => {
  return (
    <IssuesContext.Provider value={contextValues}>
      {children}
    </IssuesContext.Provider>
  )
}

export const useIssuesContext = () => {
  const context = useContext(IssuesContext)
  invariant(context, "useIssuesContext must be used within a IssuesProvider")
  return context
}

const HEADERS: AdminTableHeader[] = [
  { label: "Title", isPinned: true },
  { label: "Issue No." },
  { label: "Fulfillment Start" },
  { label: "Published to Archive" },
  { label: "Actions" },
]

const pinnedColumnStyles =
  "sticky left-0 bg-gradient-to-r from-white from-80% to-transparent group-hover:from-muted group-hover:from-80% group-hover:to-transparent"

const IssueRow = ({ issue }: { issue: Issue_AdminFragment }) => {
  const { setIssues } = useIssuesContext()
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)

  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)
  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  const handleEdit = () => {
    setIsEditDialogOpen(true)
  }

  const handleSaved = (issue: Issue_AdminFragment) => {
    setIssues((prev) => {
      const index = prev.findIndex((i) => i.id === issue.id)
      if (index === -1) {
        return [issue, ...prev]
      }

      return [...prev.slice(0, index), issue, ...prev.slice(index + 1)]
    })
  }

  return (
    <>
      <IssueDialog
        issue={issue}
        open={isEditDialogOpen}
        setOpen={setIsEditDialogOpen}
        onSaved={handleSaved}
      />
      <ContextMenu>
        <ContextMenuContent>
          <ContextMenuItem className="cursor-pointer" onClick={handleEdit}>
            Edit
          </ContextMenuItem>
        </ContextMenuContent>
        <ContextMenuTrigger asChild>
          <TableRow ref={contextMenuTriggerRef} className="group">
            <TableCell className={pinnedColumnStyles}>{issue.title}</TableCell>
            <TableCell>{issue.number}</TableCell>
            <TableCell>{formatCalendarDate(issue.fulfillmentStart)}</TableCell>
            <TableCell>
              {issue.availableInLibrary ? (
                <Badge>Yes</Badge>
              ) : (
                <Badge variant="destructive">No</Badge>
              )}
            </TableCell>
            <TableCell>
              <Button variant="ghost" size="icon" onClick={triggerContextMenu}>
                <DotsHorizontalIcon />
              </Button>
            </TableCell>
          </TableRow>
        </ContextMenuTrigger>
      </ContextMenu>
    </>
  )
}

interface IssuesTableProps {
  loading: boolean
  error?: Error
  issues?: Issue_AdminFragment[]
  hasNextPage: boolean
  hasPreviousPage: boolean
  goToNextPage: () => void
  goToPreviousPage: () => void
}

export const IssuesTable = ({
  loading,
  error,
  issues,
  hasNextPage,
  hasPreviousPage,
  goToNextPage,
  goToPreviousPage,
}: IssuesTableProps) => {
  if (loading) return <LoadingIndicatorCentered />
  if (error) return <Error message={error.message} />

  if (!issues || issues.length === 0) {
    return (
      <Card className="max-w-96 w-full">
        <CardContent className="pt-11 h-32 w-full flex items-center justify-center">
          <h6 className={typographyVariants({ variant: "h6" })}>
            No issues found.
          </h6>
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            {HEADERS.map((header) => (
              <TableHead
                key={header.label}
                variant={header.isPinned ? "pinned" : "default"}
              >
                {header.label}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {issues.map((issue) => (
            <IssueRow key={issue.id} issue={issue} />
          ))}
        </TableBody>
      </Table>
      <Pagination>
        <PaginationContent className="flex w-full justify-between">
          <PaginationItem>
            {hasPreviousPage && (
              <Button
                variant="outline"
                size="sm"
                onClick={goToPreviousPage}
                disabled={!hasPreviousPage}
              >
                <div className="flex items-center gap-2">
                  <ChevronLeft className="w-5 h-5" />
                  <span>Previous</span>
                </div>
              </Button>
            )}
          </PaginationItem>
          <PaginationItem>
            {hasNextPage && (
              <Button
                variant="outline"
                size="sm"
                onClick={goToNextPage}
                disabled={!hasNextPage}
              >
                <div className="flex items-center gap-2">
                  <span>Next</span>
                  <ChevronRight className="w-5 h-5" />
                </div>
              </Button>
            )}
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </>
  )
}

gql(`
  fragment Issue_Admin on Issue {
    id
    title
    number
    fulfillmentStart
    availableInLibrary
    embedCode
    createdAt
    updatedAt
  }
`)
