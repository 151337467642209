import { Outlet } from "react-router-dom"
import * as Sentry from "@sentry/react"
import * as React from "react"
import { isNotFoundError } from "~/errors"
import { usePageTitle } from "~/common/usePageTitle"

const Fallback: Sentry.FallbackRender = ({ error }) => {
  usePageTitle(isNotFoundError(error) ? "404 Not Found" : "Error")

  if (isNotFoundError(error)) {
    // @TODO: Add a custom 404 page
    return (
      <div className="max-w-7xl mx-auto">
        <div className="border border-red-700 p-4 py-2">
          <h1 className="text-red-700">{error.statusText}</h1>
        </div>
      </div>
    )
  }

  return (
    <div className="max-w-7xl mx-auto">
      <div className="border border-red-700 p-4 py-2">
        <h1 className="text-red-700">{error?.toString()}</h1>
      </div>
    </div>
  )
}

export const ErrorBoundary = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Sentry.ErrorBoundary fallback={Fallback}>
      {children ? children : <Outlet />}
    </Sentry.ErrorBoundary>
  )
}
