import { useEffect } from "react"
import { useLandingContext } from "~/layouts/LandingLayout"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { typographyVariants } from "~/ui/Typography"
import { getMetaVar } from "~/common/getMetaVar"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { usePageTitle } from "~/common/usePageTitle"
import { MarketingFeatures } from "~/subscription-plans/MarketingFeatures"
import { Hero } from "~/ui/Hero"
// import Logo from "~/images/logo.svg?react"
import { Callout } from "~/ui/Callout"
import { Image } from "~/ui/Image"

export const SubscribeScreen = () => {
  usePageTitle("Subscribe to Racquet Magazine")
  const { setTitle } = useLandingContext()
  useEffect(() => setTitle("Membership"), [setTitle])
  const csrfToken = getMetaVar("csrf-token")

  const { data } = useQuery(SUBSCRIPTION_PLANS_QUERY)
  const subscriptionPlans = data?.subscriptionPlans?.edges.map(
    (edge) => edge.node
  )
  const plan = subscriptionPlans?.find(
    (plan) => plan?.name === "Racquet Subscription"
  )
  const latestIssue = data?.latestIssue

  if (!plan) return null
  return (
    <div className="flex flex-col gap-2">
      <Hero>
        <div className="container xl:max-w-[1024px] mx-auto">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <Card variant="noBorder">
              <CardHeader>
                <h2 className={typographyVariants({ variant: "cardType" })}>
                  Join Today!
                </h2>
                <CardTitle variant="h2">
                  Racquet – the global media and lifestyle tennis brand.
                </CardTitle>
              </CardHeader>
              <CardContent className="font-display-alternate flex flex-col gap-4">
                {plan.description && <p>{plan.description}</p>}
                {latestIssue && (
                  <p>Join today to get Issue No. {latestIssue.number}!</p>
                )}
              </CardContent>
            </Card>
            <div>
              <Card variant="subscription" heft="light">
                <CardHeader>
                  <h5 className={typographyVariants({ variant: "h5" })}>
                    {plan.name}
                  </h5>
                  <div className="text-2xl font-bold">
                    {plan.unitAmountFormatted}
                  </div>
                  <div>
                    {plan.intervalCount === 1 ? (
                      <>
                        Per <span className="capitalize">{plan.interval}</span>
                      </>
                    ) : (
                      <>
                        Every {plan.intervalCount}{" "}
                        <span className="capitalize">{plan.interval}s</span>
                      </>
                    )}
                  </div>
                </CardHeader>
                <CardContent className="flex flex-col gap-6">
                  <form method="POST" action="/checkout">
                    <input
                      type="hidden"
                      name="authenticity_token"
                      value={csrfToken}
                    />
                    <input
                      type="hidden"
                      name="stripe_price_id"
                      value={plan.id}
                    />
                    <Button type="submit" size="full">
                      Get started
                    </Button>
                  </form>
                  <MarketingFeatures features={plan.marketingFeatures} />
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </Hero>
      <Hero>
        <Card variant="noBorder" className="text-center">
          <CardHeader>
            <h4 className={typographyVariants({ variant: "cardType" })}>
              What's Included
            </h4>
            <CardTitle>
              Three issues of Racquet,
              <br />
              the award-winning print title
            </CardTitle>
          </CardHeader>
          <CardContent className="flex items-center justify-center">
            <Image
              image="Stack"
              alt="Stack of Racquet Magazines"
              className="lg:max-w-[588px] md:max-w-[460px] sm:max-w-[340px] max-w-[300px] w-full"
            />
          </CardContent>
        </Card>
      </Hero>
      {/* <Callout alignment="right" image="Cheers">
        VIP Invites to exclusive events, pop ups and experiences
      </Callout>
      <Callout alignment="left" image="Practice">
        First Looks: Get first crack at our best-selling merch drops before they
        sell out
      </Callout>
      <Callout alignment="right" image="Escape">
        Adventures: Priority booking for Racquet trips and experiences across
        the world
      </Callout> */}
      <Callout alignment="left" image="Archives">
        Access to the complete Racquet Archives (back issues)
      </Callout>

      {/* <Hero variant="gucci" size="vignette">
        <Card variant="noBorder" className="text-center">
          <CardHeader>
            <Logo className="w-[78px] h-[10px] fill-gray-333 mx-auto mb-4" />
            <CardTitle>{plan.name}</CardTitle>
          </CardHeader>
          <CardContent>
            <form method="POST" action="/checkout">
              <input
                type="hidden"
                name="authenticity_token"
                value={csrfToken}
              />
              <input
                type="hidden"
                name="stripe_price_id"
                value={plan.id}
              />
              <Button type="submit" size="full">
                Join now
              </Button>
            </form>
          </CardContent>
        </Card>
      </Hero> */}
    </div>
  )
}

const SUBSCRIPTION_PLANS_QUERY = gql(`
  query SubscriptionPlans {
    latestIssue {
      id
      title
      number
    }

    subscriptionPlans {
      edges {
        node {
          id
          name
          description
          marketingFeatures
          unitAmountFormatted
          interval
          intervalCount
        }
      }
    }
  }
`)
