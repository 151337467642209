import { VariantProps, cva } from "class-variance-authority"
import * as React from "react"
import { cn } from "~/common/shadcn-utils"
import { BackgroundImage } from "./BackgroundImage"
import { Types as ImageTypes } from "~/images"
import { useEffect, useState } from "react"

const heroVariants = cva("relative", {
  variants: {
    variant: {
      default: "bg-neutral-100 p-4 sm:p-8 md:p-16",
      ready: "bg-gradient-to-br from-outerSpace to-tomThumb",
      grass: "bg-gradient-to-br from-mineralGreen to-avocado",
      gucci: "bg-gradient-to-br from-slate-200 to-slate-100",
      net: "bg-gradient-to-br from-nepal to-stone-300",
      court: "bg-gradient-to-br from-mineralGreen to-avocado",
    },
    size: {
      default: "",
      vignette: "h-[325px]",
      full: "h-screen w-screen",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
})

const backgroundImageVariants = cva(
  "w-full h-full flex justify-center items-center bg-cover bg-center",
  {
    variants: {
      variant: {
        default: "",
        ready: "",
        grass: "",
        gucci: "!bg-top",
        net: "lg:bg-left bg-right",
        court: "bg-top",
      },
    },
  }
)

interface HeroProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof heroVariants> {
  fixedUntilScroll?: boolean
}

export const Hero = React.forwardRef<HTMLDivElement, HeroProps>(
  (
    { variant, size, className, style, children, fixedUntilScroll, ...props },
    ref
  ) => {
    const [isAtTop, setIsAtTop] = useState(true)
    let imageType: ImageTypes | undefined

    if (variant === "grass") {
      imageType = "HeroGrass"
    } else if (variant === "gucci") {
      imageType = "HeroGucci"
    } else if (variant === "net") {
      imageType = "HeroNet"
    } else if (variant === "ready") {
      imageType = "HeroReady"
    } else if (variant === "court") {
      imageType = "HeroCourt"
    }

    useEffect(() => {
      const handleScroll = () => {
        if (!fixedUntilScroll) return
        setIsAtTop(window.scrollY <= 0)
      }

      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }, [fixedUntilScroll])

    return (
      <>
        <div
          className={cn(
            heroVariants({ variant, size, className }),
            fixedUntilScroll && isAtTop && "fixed top-0 left-0 w-full z-10"
          )}
          ref={ref}
          {...props}
        >
          {imageType ? (
            <BackgroundImage
              image={imageType}
              className={cn(backgroundImageVariants({ variant }))}
            >
              {children}
            </BackgroundImage>
          ) : (
            children
          )}
        </div>
        {fixedUntilScroll && isAtTop && (
          <div
            className="h-[325px] w-full"
            style={{ visibility: "hidden" }}
          ></div>
        )}
      </>
    )
  }
)
